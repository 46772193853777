<nav
  [ngClass]="{'h-screen bg-white flex justify-start items-center flex-col overflow-auto border-r border-zinc-100 w-[300px] transition-all duration-300 ease-in-out z-[99999]': sidebarExpanded, 'w-[95px] transition-all duration-300 ease-in-out': !sidebarExpanded}">

  <div class="relative w-full" (click)="changeWidthSidebar()">
    <div class="absolute top-16 right-2 cursor-pointer">
      <svg *ngIf="sidebarExpanded" width="28" height="28" viewBox="0 0 28 28" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="27" height="27" rx="7.5" fill="white" />
        <rect x="0.5" y="0.5" width="27" height="27" rx="7.5" stroke="#F6F6F6" />
        <path
          d="M16.4714 10.4714C16.7317 10.2111 16.7317 9.78895 16.4714 9.5286C16.211 9.26825 15.7889 9.26825 15.5286 9.5286L11.5286 13.5286C11.2682 13.7889 11.2682 14.2111 11.5286 14.4714L15.5286 18.4714C15.7889 18.7318 16.211 18.7318 16.4714 18.4714C16.7317 18.2111 16.7317 17.7889 16.4714 17.5286L12.9428 14L16.4714 10.4714Z"
          fill="black" />
      </svg>
      <svg *ngIf="!sidebarExpanded" width="28" height="28" viewBox="0 0 28 28" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="27" height="27" rx="7.5" fill="white" />
        <rect x="0.5" y="0.5" width="27" height="27" rx="7.5" stroke="#F6F6F6" />
        <path
          d="M11.5286 17.5286C11.2682 17.789 11.2682 18.2111 11.5286 18.4714C11.7889 18.7318 12.211 18.7318 12.4714 18.4714L16.4714 14.4714C16.7317 14.2111 16.7317 13.789 16.4714 13.5286L12.4714 9.5286C12.211 9.26825 11.7889 9.26825 11.5286 9.5286C11.2682 9.78895 11.2682 10.2111 11.5286 10.4714L15.0572 14L11.5286 17.5286Z"
          fill="black" />
      </svg>
    </div>
  </div>
  <div class="w-10/12 border-b border-zinc-100 pl-2 mb-8">
    <div *ngIf="sidebarExpanded" class="w-full h-[50px] bg-no-repeat mt-6">
      <img src="../../../assets/images/trendmulti@4x.png" width="180px"/>
    </div>
    <div *ngIf="!sidebarExpanded" class="w-full h-[70px] bg-no-repeat"
      style="background-image: url('../../../assets/images/logo-icon-sidebar.png');"></div>
  </div>
  <div class="w-full h-[90%] overflow-auto px-4 flex flex-col gap-[1rem] pb-5">
    <a class="flex items-center gap-[10px] p-2 h-[40px] no-underline font-normal text-zinc-600 text-[16px] rounded-lg hover:bg-gray-ligth hover:text-indigo-600 active:text-indigo-600 focus:text-indigo-600 active:bg-gray-ligth focus:bg-gray-ligth"
      href="javascript:void(0)" [routerLink]="['/home']" routerLinkActive="active">
      <div class="flex items-center w-[30px] h-[25px]">
        <svg
          class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
          aria-hidden="true" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 20.5V10.5M12 20.5V4.5M6 20.5V14.5" stroke="#667085" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div>
      <span *ngIf="sidebarExpanded">Dashboard</span>
      <span *ngIf="!sidebarExpanded" class="hidden hover:block hover:z-50">Dashboard</span>
    </a>
    <a class="flex items-center gap-[10px] p-2 h-[40px] no-underline font-normal text-zinc-600 text-[16px] rounded-lg hover:bg-gray-ligth hover:text-indigo-600 active:text-indigo-600 focus:text-indigo-600 active:bg-gray-ligth focus:bg-gray-ligth"
      href="javascript:void(0)" [routerLink]="['/company']" routerLinkActive="active">
      <div class="flex items-center w-[30px] h-[25px]">
        <svg width="24" height="25" viewBox="0 0 338.32 325.74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M287.8,20c2.07,0,3.75,1.68,3.75,3.75v281.99h-141.63V23.75c0-2.07,1.68-3.75,3.75-3.75h134.14m0-20h-134.14c-13.12,0-23.75,10.63-23.75,23.75v291.15c0,5.99,4.85,10.84,10.84,10.84h160.24c5.83,0,10.55-4.72,10.55-10.55V23.75c0-13.12-10.63-23.75-23.75-23.75h0Z"
            stroke="#667085" stroke-width="20" fill="none" />
          <path
            d="M126.75,155.11c2.07,0,3.75,1.68,3.75,3.75v146.89H47.04v-146.89c0-2.07,1.68-3.75,3.75-3.75h75.96m0-20H50.79c-13.12,0-23.75,10.63-23.75,23.75v156.05c0,5.99,4.85,10.84,10.84,10.84h102.06c5.83,0,10.55-4.72,10.55-10.55v-156.34c0-13.12-10.63-23.75-23.75-23.75h0Z"
            stroke="#667085" stroke-width="20" fill="none" />
          <line x1="10" y1="314.45" x2="328.32" y2="314.45" stroke="#667085" stroke-width="20" stroke-linecap="round"
            stroke-linejoin="round" />
          <line x1="72.98" y1="237.32" x2="103.18" y2="237.32" stroke="#667085" stroke-width="20" stroke-linecap="round"
            stroke-linejoin="round" />
          <line x1="204.56" y1="234.47" x2="234.76" y2="234.47" stroke="#667085" stroke-width="20"
            stroke-linecap="round" stroke-linejoin="round" />
          <line x1="205.55" y1="162.87" x2="235.75" y2="162.87" stroke="#667085" stroke-width="20"
            stroke-linecap="round" stroke-linejoin="round" />
          <line x1="205.64" y1="87.69" x2="235.83" y2="87.69" stroke="#667085" stroke-width="20" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div>
      <span *ngIf="sidebarExpanded">Empresas</span>
      <span *ngIf="!sidebarExpanded" class="hidden hover:block hover:z-50">Empresa</span>
    </a>
    
    <a class="flex items-center gap-[10px] p-2 h-[40px] no-underline font-normal text-zinc-600 text-[16px] rounded-lg hover:bg-gray-ligth hover:text-indigo-600 active:text-indigo-600 focus:text-indigo-600 active:bg-gray-ligth focus:bg-gray-ligth"
      href="javascript:void(0)" [routerLink]="['/webhook']" routerLinkActive="active">

      <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="code"></mat-icon>
      <span *ngIf="sidebarExpanded">Webhook</span>
      <span *ngIf="!sidebarExpanded" class="hidden hover:block hover:z-50">Webhook</span>
    </a>

  </div>
  <!-- Config -->
</nav>