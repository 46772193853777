import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'lib-trend-core';

@Component({
  selector: 'header-component',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void { }

  logout() {
    this.authenticationService.logout();
    window.location.href = '/login';
  }

}