import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material/select';
import { AbstractComponent, ChannelTypeEnum, Company, CompanyService, Pager } from 'lib-trend-core';
import { debounceTime, distinctUntilChanged, mergeMap, Observable } from 'rxjs';

@Component({
  selector: 'dashboard-component',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent extends AbstractComponent implements OnInit, OnDestroy {
  @ViewChild('picker', { static: true }) picker: MatDatepicker<Date>;

  filterControlCompanies: FormControl = new FormControl('');

  pager: Pager<Company> = new Pager<Company>({ perPage: 10 });
  listObservable: Observable<Pager<Company>>;

  totalCompanies: number = 0;
  totalChannels: number = 0;
  totalUsers: number = 0;
  MRR: number = 0;
  ARR: number = 0;
  totalChurn: number = 0;
  ARPU: number = 0;
  mediumTicket: number = 0;
  LTV: number = 0;

  colorScheme: any = {
    domain: ['#aa98ec', '#4213f6']
  };

  isPicker: boolean = false;
  ARRData: any[] = [];
  ARPUData: any[] = [];
  mediumTicketData: any[] = [];
  LTVData: any[] = [];

  listCompanies!: Array<Company>;
  allCompanies!: Array<Company>;
  selectedCompaniesId: Array<string> | null = new Array<string>();
  labelFilterCompany: string = 'Empresas';

  labelFilterTypeChannel: string = 'Tipos de canais';

  CloudApi: ChannelTypeEnum = ChannelTypeEnum.CLOUD_API;
  EvoApi: ChannelTypeEnum = ChannelTypeEnum.EVOLUTION_API;
  EvoGoApi: ChannelTypeEnum = ChannelTypeEnum.EVOLUTION_GO_API;

  constructor(
    injetor: Injector,
    private companyService: CompanyService,
  ) {
    super(injetor);
    this.createForm();
    this.getList();
    this.fetchFilterCompanies();
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
    ).subscribe(val => {
      this.getCounts();
    });

    this.getCounts();
  }

  override ngOnDestroy(): void {
  }

  private createForm(): void {
    const today = new Date();
    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getFullYear() - 1);

    this.formGroup = this.formBuilder.group({
      dateRange: this.formBuilder.group({
        dateStart: [oneYearAgo],
        dateEnd: [today],
      }),
      companies: [[], []],
      typeChannel: [null, []],
    });
  }

  private getCounts(): void {
    this.searchParams = {};

    const dateRange = this.formGroup.get('dateRange')?.value;
    if (dateRange?.dateStart && dateRange?.dateEnd) {
      this.searchParams['dateStart'] = dateRange.dateStart.getTime();
      this.searchParams['dateEnd'] = dateRange.dateEnd.getTime();
    }

    const companies = this.formGroup.get('companies')?.value;
    if (companies && companies.length > 0) {
      this.searchParams['companies'] = companies;
    }

    const typeChannel = this.formGroup.get('typeChannel')?.value;
    if (typeChannel) {
      this.searchParams['typeChannel'] = typeChannel;
    }

    this.companyService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams)
      .pipe(mergeMap((pager: Pager<Company>) => {
        this.pager = pager;
        return this.companyService.getCountsForPanelAdm(this.searchParams);
      }))
      .subscribe({
        next: (counts) => {
          counts.filter((count) => count).forEach((count) => {
            this.totalCompanies = count.totalCompanies;
            this.totalChannels = count.totalChannels;
            this.totalUsers = count.totalUsers;
            this.MRR = count.MRR;
            this.ARR = count.ARR;
            this.totalChurn = count.totalChurn;
            this.ARPU = count.ARPU;
            this.mediumTicket = count.mediumTicket;
            this.LTV = count.LTV;
  
            const oldestDate = new Date(dateRange.dateStart);
            const newestDate = new Date(dateRange.dateEnd);
  
            this.ARRData = [{
              name: 'ARR',
              series: [
                {
                  name: oldestDate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'short' }),
                  value: this.ARR
                },
                {
                  name: newestDate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'short' }),
                  value: this.ARR
                }
              ]
            }];
  
            this.ARPUData = [
              { name: 'ARPU Inicial', value: this.ARPU },
              { name: 'ARPU Atual', value: this.ARPU }
            ];
  
            this.mediumTicketData = [
              { name: 'Ticket Médio', value: this.mediumTicket },
              { name: 'Outros', value: this.mediumTicket * 0.2 }
            ];
  
            this.LTVData = [{
              name: 'LTV',
              series: [
                { name: oldestDate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'short' }), value: this.LTV },
                { name: newestDate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'short' }), value: this.LTV }
              ]
            }];
          });
        },
        error: (err: Error) => this.alertService.error(err.message),
      });

    // this.companyService.getCountsForPanelAdm(this.searchParams).subscribe({
    //   next: (counts) => {
    //     counts.filter((count) => count).forEach((count) => {
    //       this.totalCompanies = count.totalCompanies;
    //       this.totalChannels = count.totalChannels;
    //       this.totalUsers = count.totalUsers;
    //       this.MRR = count.MRR;
    //       this.ARR = count.ARR;
    //       this.totalChurn = count.totalChurn;
    //       this.ARPU = count.ARPU;
    //       this.mediumTicket = count.mediumTicket;
    //       this.LTV = count.LTV;

    //       const oldestDate = new Date(dateRange.dateStart);
    //       const newestDate = new Date(dateRange.dateEnd);

    //       this.ARRData = [{
    //         name: 'ARR',
    //         series: [
    //           {
    //             name: oldestDate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'short' }),
    //             value: this.ARR
    //           },
    //           {
    //             name: newestDate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'short' }),
    //             value: this.ARR
    //           }
    //         ]
    //       }];

    //       this.ARPUData = [
    //         { name: 'ARPU Inicial', value: this.ARPU },
    //         { name: 'ARPU Atual', value: this.ARPU }
    //       ];

    //       this.mediumTicketData = [
    //         { name: 'Ticket Médio', value: this.mediumTicket },
    //         { name: 'Outros', value: this.mediumTicket * 0.2 }
    //       ];

    //       this.LTVData = [{
    //         name: 'LTV',
    //         series: [
    //           { name: oldestDate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'short' }), value: this.LTV },
    //           { name: newestDate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'short' }), value: this.LTV }
    //         ]
    //       }];
    //     });
    //   },
    //   error: (err) => console.error(err.error),
    // });
  };

  onPeriodChange(event: any): void {
    const today = new Date();
    switch (event.value) {
      case '1':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '7':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 7)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '15':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 15)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '30':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 30)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '60':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 60)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
    }
  }

  openPicker(): void {
    this.isPicker = true;
    setTimeout(() => {
      this.picker.open();
    }, 300);
  }

  searchByCompany(company: Company): void {
    if (!!company) {
      this.formGroup.get('company')?.setValue(company._id);
      this.labelFilterCompany = company.name;
    } else {
      this.formGroup.get('company')?.setValue(null);
      this.labelFilterCompany = 'Empresas';
    }
  }

  searchByTypeChannel(typeChannel: ChannelTypeEnum): void {
    if (!!typeChannel) {
      this.formGroup.get('typeChannel')?.setValue(typeChannel);
      this.labelFilterTypeChannel = typeChannel === ChannelTypeEnum.CLOUD_API ? 'Oficial' : typeChannel === ChannelTypeEnum.EVOLUTION_API ? 'Não oficial' : 'Não oficial - PRO'; 
    } else {
      this.formGroup.get('typeChannel')?.setValue(null);
      this.labelFilterTypeChannel = 'Tipos de canais';
    }
  }

  private fetchFilterCompanies(): void {
    this.filterControlCompanies.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((value: string) => {
      const currentValue = this.selectedCompaniesId || [];

      this.allCompanies = this.listCompanies.filter(company =>
        company.name.toLowerCase().includes(value.toLowerCase())
      );

      currentValue.forEach(companyId => {
        const existingCompany = this.listCompanies.find(company => company._id === companyId);
        if (existingCompany && !this.allCompanies.includes(existingCompany)) {
          this.allCompanies.push(existingCompany);
        }
      });
    });
  }

  onFocusCompanies(): void {
    this.allCompanies = this.listCompanies;
  }

  selectCompanies(event: MatCheckboxChange): void {
    const companiesControl = this.formGroup.get('companies');
    const currentCompanies = companiesControl.value;

    if (event.checked) {
      companiesControl.setValue([...currentCompanies, event.source.value]);
    } else {
      companiesControl.setValue(currentCompanies.filter((id) => id !== event.source.value));
    }
  }

  private getList(): void {
    this.companyService.getList().subscribe({
      next: (list: Array<Company>) => {
        this.listCompanies = list;
        this.allCompanies = list;
      },
      error: (err: Error) => this.alertService.error(err.message),
    });
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getCounts();
  }
}
