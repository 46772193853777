import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, UtilHelper } from 'lib-trend-core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'sidebar-component',
  templateUrl: './sidebar.component.html',
})
export class SideBarComponent extends AbstractComponent implements OnInit {

  sidebarExpanded = false;

  constructor(
    public injector: Injector,
  ) { 
    super(injector);
  }

  ngOnInit(): void {
  }

  changeWidthSidebar() {
    this.sidebarExpanded = !this.sidebarExpanded;
  }

  goToWebAdmin() {
    const idUser = super.getIDCurrentUser();
    const idCompany = super.getIDCurrentCompany();
    const base64 = UtilHelper.generateTokenTowardsApps(idUser, idCompany);
    window.open(`${environment.trendWebAdminUrl}/redirect?token=${base64}`, '_blank');
  }
}
