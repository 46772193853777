import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from "lib-trend-core";
import { BaseComponent } from './base/base.component';
import { FooterComponent } from './footer.component';
import { HeaderComponent } from './header.component';
import { SideBarComponent } from './sidebar.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    //FormsModule,∏
    //BrowserModule,
    HttpClientModule,
    SharedModule
  ],
  declarations: [
    BaseComponent,
    HeaderComponent,
    SideBarComponent,
    FooterComponent
  ],
  exports: [
    BaseComponent,
    HeaderComponent,
    SideBarComponent,
    FooterComponent
  ],
  providers: [
  ]
})

export class LayoutModule { }
