import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CardComponentModule, SharedModule } from 'lib-trend-core';
import { DashboardComponent } from './dashboard.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  imports: [
    SharedModule,
    CardComponentModule,
    NgxChartsModule,
  ],
  declarations: [
    DashboardComponent,
  ],
  exports: [
    DashboardComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardModule { }
