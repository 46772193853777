<div class="flex flex-1 w-full top-0  px-8 py-4">
  <div class="flex w-full justify-between items-center border-b border-[#BBBCBF] pb-2.5 pt-2">
    <div>
      <h3 class="text-indigo-600 font-bold">Trend | Painel de Administração Empresarial</h3>
    </div>
    <div class="flex justify-end items-center gap-[20px]">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.4417 27.5001C21.2952 27.7526 21.0849 27.9623 20.8319 28.108C20.5788 28.2538 20.292 28.3305 20 28.3305C19.708 28.3305 19.4212 28.2538 19.1681 28.108C18.9151 27.9623 18.7048 27.7526 18.5583 27.5001M25 16.6667C25 15.3407 24.4732 14.0689 23.5355 13.1312C22.5979 12.1935 21.3261 11.6667 20 11.6667C18.6739 11.6667 17.4021 12.1935 16.4645 13.1312C15.5268 14.0689 15 15.3407 15 16.6667C15 22.5001 12.5 24.1667 12.5 24.1667H27.5C27.5 24.1667 25 22.5001 25 16.6667Z"
          stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
      </svg>

      <button mat-icon-button class="flex items-center justify-center h-[48px] w-[48px] rounded-full bg-secondary-lilas"
        [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="{name: 'João'}">
        <mat-icon>person</mat-icon>
      </button>
      <mat-menu #appMenu="matMenu">
        <ng-template matMenuContent let-name="name">
          <button mat-menu-item>Configurações</button>
          <button mat-menu-item (click)="logout()">Sair</button>
        </ng-template>
      </mat-menu>

    </div>
  </div>
</div>