import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'lib-trend-core';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { BaseComponent } from './modules/layout/base/base.component';

const routes: Routes = [

  { path: 'login', loadChildren: () => import('../app/modules/login/login.module').then(m => m.LoginModule) },

  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'company', pathMatch: 'full' },
      { path: 'home', component: DashboardComponent, },
      { path: 'company', loadChildren: () => import('../app/modules/company/company.module').then(m => m.CompanyModule) },
      { path: 'user', loadChildren: () => import('../app/modules/user-admin/user-admin.module').then(m => m.UserAdminModule) },
      { path: 'webhook', loadChildren: () => import('../app/modules/webhook/webhook.module').then(m => m.WebhookModule) },
    ],
  },

  { path: '', redirectTo: 'company', pathMatch: 'full' },
  { path: '**', redirectTo: 'company', pathMatch: 'full' },

];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
