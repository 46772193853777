<div class="w-full h-full py-8 px-4">
  <div class="w-full p-3">
    <form [formGroup]="formGroup">
      <!-- Filter date -->
      <div class="flex justify-end">
        <div id="input-phone" class="bg-white flex pl-2 items-center w-auto h-[100%] border rounded-lg">
          <mat-date-range-input [rangePicker]="picker" formGroupName="dateRange">
            <input matStartDate placeholder="Data inicial" formControlName="dateStart">
            <input matEndDate placeholder="Data final" formControlName="dateEnd">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker (selectionChange)="onPeriodChange($event)">
            <mat-datepicker-actions>
              <mat-select class="input-field" (selectionChange)="onPeriodChange($event)">
                <mat-option value="1">Hoje</mat-option>
                <mat-option value="7">Últimos 7 dias</mat-option>
                <mat-option value="15">Últimos 15 dias</mat-option>
                <mat-option value="30">Últimos 30 dias</mat-option>
                <mat-option value="60">Últimos 60 dias</mat-option>
              </mat-select>
              <button mat-raised-button matDatepickerApply class="btnOk">OK</button>
            </mat-datepicker-actions>
          </mat-date-range-picker>
        </div>
        <!-- Filter company -->
        <button type="submit" [matMenuTriggerFor]="companiesMenu"
          class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm font-medium text-gray-900 global-filter">
          <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
              fill="#667085" />
          </svg>
          <span>{{labelFilterCompany}}</span>
        </button>
        <mat-menu #companiesMenu="matMenu" [class]="'menu-tags'">
          <div class="input-select-filter w-full p-2 mb-2">
            <input placeholder="Escreva o nome da empresa" class="input-select-filter input-field" type="text"
              (focus)="onFocusCompanies()" [formControl]="filterControlCompanies" (click)="$event.stopPropagation()" />
          </div>
          @if (allCompanies.length > 0) {
          @for (company of allCompanies; track $index) {
          <div class="flex flex-col">
            <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="selectCompanies($event)"
              [value]="company._id" [checked]="
                formGroup.get('companies').value.includes(company._id)
              ">{{ company.name }}</mat-checkbox>
          </div>
          } } @else {
          <h6 class="text-zinc-400 font-light text-sm mt-[15px] p-2">
            Nenhum resultado encontrado.
          </h6>
          }
        </mat-menu>

        <!-- Filter type channel -->
        <button type="submit" [matMenuTriggerFor]="typeChannelMenu"
          class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm font-medium text-gray-900 global-filter">
          <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
              fill="#667085" />
          </svg>
          <span>{{labelFilterTypeChannel}}</span>
        </button>
        <mat-menu #typeChannelMenu="matMenu" [class]="'menu-tags'">
          <button mat-menu-item (click)="searchByTypeChannel(null)">
            Todos
          </button>
          <button mat-menu-item (click)="searchByTypeChannel(CloudApi)">
            Oficial
          </button>
          <button mat-menu-item (click)="searchByTypeChannel(EvoApi)">
            Não oficial
          </button>
          <button mat-menu-item (click)="searchByTypeChannel(EvoGoApi)">
            Não oficial - PRO
          </button>
        </mat-menu>
      </div>
    </form>
  </div>

  <div class="w-full py-8 px-4 mt-[15px]">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-2.5 sm:gap-3">
      <card-graphic-small-component [label]="'Total de empresas'"
        [total]="totalCompanies.toString()"></card-graphic-small-component>
      <card-graphic-small-component [label]="'Total de canais'"
        [total]="totalChannels.toString()"></card-graphic-small-component>
      <card-graphic-small-component [label]="'Total de usuários'"
        [total]="totalUsers.toString()"></card-graphic-small-component>
      <card-graphic-small-component [label]="'MRR'" [total]="'R$ ' + MRR.toString()"></card-graphic-small-component>
      <card-graphic-small-component [label]="'Total de churn'"
        [total]="totalChurn.toString()"></card-graphic-small-component>
    </div>

    <div class="flex gap-2 mt-[15px]">
      <div class="card-container-chart bg-white">
        <div class="mb-[15px]">
          <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">ARR</h1>
          <h6 class="text-zinc-400 font-light text-sm">
            Soma de todas as mensalidades vezes 12
          </h6>
        </div>
        <mat-card-content>
          <ngx-charts-line-chart [view]="[400, 300]" [scheme]="colorScheme" [results]="ARRData" [xAxis]="true"
            [yAxis]="true" [legend]="false" [showXAxisLabel]="true" [showYAxisLabel]="true" [autoScale]="true">
          </ngx-charts-line-chart>
        </mat-card-content>
      </div>
      <!-- ARPU - Gráfico de Barras Verticais -->
      <div class="card-container-chart bg-white">
        <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">ARPU</h1>
        <h6 class="text-zinc-400 font-light text-sm">
          Valor por usuário
        </h6>
        <ngx-charts-bar-vertical [view]="[400, 300]" [scheme]="colorScheme" [results]="ARPUData" [xAxis]="true"
          [yAxis]="true" [legend]="false" [showXAxisLabel]="true" [showYAxisLabel]="true" [autoScale]="true">
        </ngx-charts-bar-vertical>
      </div>
    </div>

    <div class="flex gap-2 mt-3">
      <!-- Ticket Médio - Gráfico de Pizza -->
      <div class="card-container-chart bg-white">
        <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Ticket Médio</h1>
        <h6 class="text-zinc-400 font-light text-sm">
          Ticket médio dos clientes na plataforma
        </h6>
        <ngx-charts-pie-chart [view]="[400, 300]" [scheme]="colorScheme" [results]="mediumTicketData" [legend]="true"
          [labels]="true">
        </ngx-charts-pie-chart>
      </div>

      <!-- LTV - Gráfico de Área -->
      <div class="card-container-chart bg-white">
        <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">LTV</h1>
        <h6 class="text-zinc-400 font-light text-sm">
          Valor vitálicio do cliente
        </h6>
        <ngx-charts-area-chart [view]="[400, 300]" [scheme]="colorScheme" [results]="LTVData" [xAxis]="true"
          [yAxis]="true" [legend]="false" [showXAxisLabel]="true" [showYAxisLabel]="true" [autoScale]="true">
        </ngx-charts-area-chart>
      </div>
    </div>
    <div class="w-full max-h-[calc(100vh-240px)] flex flex-wrap gap-[12px] mt-4" *ngIf="pager.total > 0">
      <div class="inline-block min-w-full  py-2 align-middle">
        <div
          class="overflow-hidden  ring-1 ring-black ring-opacity-5 rounded-lg shadow-global bg-white border-gray-100 border-2">
          <table class="min-w-full  divide-gray-100 divide-y-[3px]">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Código</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Data de criação</th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Nome</th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">CNPJ</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Email</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Telefone</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Ativa</th>
              </tr>
            </thead>
            <tbody class="divide-gray-100 divide-y-[3px] bg-white">
              <tr *ngFor="let item of pager?.list;" class="rounded-none" role="row">
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.code}}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.createdAt | date: 'dd/MM/yyyy'}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.name}}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.tin | cnpjType}}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.email}}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.phone}}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  @if(item?.actived === true) {
                  <mat-icon class="text-green-500">radio_button_checked</mat-icon>
                  } @else {
                  <mat-icon class="text-red-500">radio_button_checked</mat-icon>
                  }
                </td>
              </tr>
              <tr class=""></tr>
            </tbody>
          </table>
          <paginator *ngIf="pager.total > pager.perPage" class="flex justify-content-center mt-3 mb-3" [pager]="pager"
            (onPager)="loadPage($event)">
          </paginator>
        </div>
      </div>
    </div>
  </div>

</div>