import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractComponent } from 'lib-trend-core';

@Component({
  selector: 'dashboard-component',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent extends AbstractComponent implements OnInit, OnDestroy {

  constructor(injetor: Injector) {
    super(injetor);
  }

  ngOnInit(): void {
  }

  override ngOnDestroy(): void {
  }

}
